import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import SingleJobComponent from "./component";
import Loading from "../../components/Loading";
import { JOB_TABS_KEY, PREMIUM_ITEM_TYPES } from "../../models/SingleJob";
import {
  fetchJobDetails,
  resetJobDetailsAndBookings,
  resetSingleJobOwners,
  setJobId,
  setSelectedJobTab,
  setCartOpen,
  setPremiumJobBoardsTab,
} from "../../store/reducers/SingleJob";
import {
  getSingleJobNavigationState,
  getSingleJobState,
} from "../../store/selectors/SingleJob";
import { getCurrentUserData } from "../../store/selectors/CurrentUser";
import { setJoinedPaginationSize } from "../../store/reducers/Team";
import {
  getApplicationListState,
  getApplicationsViewState,
  getSingleJobUrlKey,
} from "../../store/selectors/Applications";
import { resetSingleJobUrlKey } from "../../store/reducers/Applications";
import { getNavigationState } from "../../store/selectors/Navigation";
import { getCustomersListState } from "../../store/selectors/Customers";

const SingleJob = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { key: id, tab } = useParams();

  const view = useSelector(getApplicationsViewState);
  const jobUrlKey = useSelector(getSingleJobUrlKey);
  const { applicationsAllowed, isAgency, lang } =
    useSelector(getCurrentUserData);
  const { jobId, jobDetails, selectedPremiumJobBoardsTab } =
    useSelector(getSingleJobState);
  const { jobTabs, selectedJobTab } = useSelector(getSingleJobNavigationState);
  const { cardsApplications, list } = useSelector(getApplicationListState);
  const { list: customersList } = useSelector(getCustomersListState);

  const [columnWidth, setColumnWidth] = useState<number>(290);
  const [anchorEl, setAnchorEl] = useState(null);

  const agencyTabs = jobTabs.filter(
    (item) => item !== JOB_TABS_KEY.APPLICATIONS
  );

  const tabs = applicationsAllowed || !isAgency ? jobTabs : agencyTabs;

  const { open } = useSelector(getNavigationState);

  const columnSpacing = 16;
  const spacingNumber = 3;
  const margin = window.innerWidth > 1536 ? 90 : 0;
  const differenceBeetwenOpenAndClosedSidebar = 150;
  const openSidebarDndWidth =
    columnWidth * 4 + columnSpacing * spacingNumber + margin;
  const closedSidebarDndWidth =
    openSidebarDndWidth + differenceBeetwenOpenAndClosedSidebar;
  const dndContainerWidth =
    cardsApplications && Object.keys(cardsApplications).length > 4
      ? open
        ? openSidebarDndWidth
        : closedSidebarDndWidth
      : "100%";

  const isCardsView =
    list.length &&
    cardsApplications &&
    Object.keys(cardsApplications).length > 4 &&
    view === "cards" &&
    selectedJobTab === JOB_TABS_KEY.APPLICATIONS;

  const isApplicationsManaged = useMemo(() => {
    if (isAgency) {
      const findCustomer = customersList.find(
        (customer) => customer.id === jobDetails?.company_id
      );
      if (findCustomer?.is_agency_managed) {
        return true;
      }
      return false;
    }
    return true;
  }, [jobDetails, isAgency]);

  const handleOnChangeTab = (_: React.SyntheticEvent, value: JOB_TABS_KEY) => {
    dispatch(setSelectedJobTab(value));
    const formattedTab = value.split(".")[1];
    if (id) {
      navigate(`/jobs/${parseInt(id)}/${formattedTab}`);
    }
  };

  const handleOnOpenCart = () => {
    dispatch(setCartOpen(true));
  };

  const handleSelectMenuItem = (option?: PREMIUM_ITEM_TYPES) => {
    const value = JOB_TABS_KEY.PREMIUM_JOB_BOARDS;
    const formattedTab = value.split(".")[1];
    if (option) {
      dispatch(setPremiumJobBoardsTab(option));
      if (id) {
        navigate(`/jobs/${parseInt(id)}/${formattedTab}`);
      }
    }
    setAnchorEl(null);
  };

  const handleClickPremiumJobBoardsTab = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (!jobId && id) {
      dispatch(setJobId({ jobId: id }));
    }
  }, []);

  useEffect(() => {
    dispatch(setSelectedJobTab(`single_job.${tab}` as JOB_TABS_KEY));
  }, [tab]);

  useEffect(() => {
    return () => {
      dispatch(setJobId({ jobId: null }));
      dispatch(resetJobDetailsAndBookings());
      dispatch(resetSingleJobUrlKey());
    };
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setJoinedPaginationSize(10));
      dispatch(resetSingleJobOwners());
    };
  }, []);

  useEffect(() => {
    if (jobUrlKey && !jobDetails) {
      dispatch(
        fetchJobDetails({
          jobUrlKey,
        })
      );
    }
  }, [jobUrlKey]);

  useEffect(() => {
    const column = document.querySelector(".dnd-column");
    if (column?.clientWidth) {
      setColumnWidth(column.clientWidth);
    }
  }, [view, cardsApplications]);

  return jobId ? (
    <SingleJobComponent
      t={t}
      jobTabs={tabs}
      isApplicationsManaged={isApplicationsManaged}
      tabsWidth={dndContainerWidth}
      view={view}
      selectedJobTab={selectedJobTab}
      cardsApplications={cardsApplications}
      list={list}
      anchorEl={anchorEl}
      selectedPremiumJobBoardsTab={selectedPremiumJobBoardsTab}
      menuOpen={Boolean(anchorEl)}
      lang={lang}
      isCardsView={Boolean(isCardsView)}
      jobDetails={jobDetails}
      onTabChange={handleOnChangeTab}
      onOpenCart={handleOnOpenCart}
      onSelectMenuItem={handleSelectMenuItem}
      onClickPremiumJobBoardsTab={handleClickPremiumJobBoardsTab}
    />
  ) : (
    <Loading />
  );
};

export default SingleJob;
